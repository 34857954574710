import React from "react";
import Header from '../Header/Header'
import './Hero.css' 
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import { motion } from "framer-motion";
import NumberCounter from 'number-counter'

const Hero = () => {
    const transition = {typeof:'spring', duration: 3}
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id="home">
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header/>
                {/*the best ad*/}
                <div className="the-best-ad">
                    <motion.div
                        initial={{left: mobile ?"165px":'225px'}}
                        whileInView={{left: '8px'}}
                        transition={{...transition, type:'tween'}}
                        ></motion.div>
                    <span>the best fitness club in arusha</span>
                </div>

            {/*Hero Heading */}
            <div className="hero-text">
                <div>
                <span className="stroke-text">Shape </span>
                <span>Your</span>
                </div>
                <div><span>
                    Ideal body</span></div>
                    <div><span>
                        Reshape your body by doing regualar exercises and you need consistent for you to develop an awesome body!</span></div>
            </div>
            
            {/* Figures*/}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} preFix="+" delay='4'/>
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span> <NumberCounter end={978} start={800} preFix="+" delay='4'/></span>
                    <span>membes joined</span>
                </div>
                <div>
                    <span> <NumberCounter end={50} start={0} preFix="+" delay='4'/></span>
                    <span>Fitness programs</span>
                </div>

            </div>
            {/*Hero buttons */}
            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
            </div>
            <div className="right-h">
                <button className="btn">Join now</button>

                {/*heart rate */}
                <motion.div className="heart-rate"
                initial = {{right: '-1rem'}}
                whileInView={{right: '4rem'}}
                transition={transition}
                >
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 Bpm</span>
                </motion.div>

                <img src={hero_image} alt=""  className="hero-img"/>
                <motion.img 
                 initial = {{right: '11rem'}}
                 whileInView={{right: '20rem'}}
                 transition={transition}
                src={hero_image_back} alt="" className="hero-img-back"/>


                {/*calories */}

                <motion.div className="calories"
                initial = {{right: '37rem'}}
                whileInView={{right: '28rem'}}
                transition={transition}
                >
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Hero


