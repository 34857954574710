import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import WhiteTick from '../../assets/whiteTick.png'
function Plans() {
  return (
   <div className="plans-container"  id='plan'>
    <div className="blur plans-blur-1"></div>
    <div className="blur plans-blur-2"></div>
    <div className="programs-header" style={{gap:'2rem'}}>
        <span className='stroke-text'>READY TO START </span>
        <span>YOUR JOURNEY </span>
        <span className='stroke-text'>NOW WITHUS?</span>
    </div>

    {/*plans card */}
    <div className="plans">
        {plansData.map((plan, index)=>(
            <div className="plan" key={index}>
                {plan.icon}
                <span>{plan.name}</span>
                <span>{plan.price} Tsh</span>

                <div className="features">
                    {plan.features.map((feature, index)=>(
                        <div className="feature">
                            <img src={WhiteTick}alt="" />
                            <span key={index}>{feature}</span>
                        </div>
                    ))}
                </div>
                <div><span>See more benefits -{'>'} </span></div>
                <button className="btn">Join now</button>
            </div>
        ))}
    </div>
   </div>
  )
}

export default Plans
