import React, { useState } from "react";
import './Header.css';
import Bars from '../../assets/bars.png'
import Logo from '../../assets/logo.png'
import { Link } from "react-scroll";

const Header = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOPened, setMenuOPened] = useState(false);
    return (

        <div className="header" >
         <img src={Logo} alt=""  className="logo" />
          {menuOPened === false && mobile === true ? (
            <div

            style={{
            background:'var(--appColor)', 
            padding:'0.5rem', 
            borderRadius:'4px'}}
            
            onClick={()=> setMenuOPened(true)}

             ><img src={Bars} alt="" style={{width:'1.5rem', height:'1.5rem'}} />
            
            </div>
        ) : (
            <ul className="header-menu">
            <li ><Link
                onClick={()=>{setMenuOPened(false)}}
                to='home'
                spy= {true}
                smooth = {true}
            >Home</Link></li>
            <li ><Link
            onClick={()=>{setMenuOPened(false)}}
                to = 'programs'
                spy = {true}
                smooth = {true}
            >Programs</Link></li>
            <li ><Link
            onClick={()=>{setMenuOPened(false)}}
                to='reasons'
                spy= {true}
                smooth = {true}
            >Why us</Link></li>
            <li ><Link
            onClick={()=>{setMenuOPened(false)}}
                to = 'plan'
                spy = {true}
                smooth = {true}
            >Plans</Link></li>
            <li ><Link
            onClick={()=>{setMenuOPened(false)}}
                to='testimonials'
                spy= {true}
                smooth = {true}
            >Testmonials</Link></li>
        </ul>
            )}
    </div>
    );
};

export default Header