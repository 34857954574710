import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image4 from '../assets/image5.jpg'

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'We train peaples of all kind in our club. We are looking for the best healthy that is our daily basis, welcome tou our fitness club ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Fitness club offers the best quality workout even for begginers, so anyone who wants to develop an ideal body fitness club is the best choice!',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  },
  {
    image : image4,
    review:' Am very excited to sayt that fitness club has changed my life to the greater extent and now am persistent and i have full energy to percist anything... ',
    name: 'WALKER',
    status: "CUSTOMER"
  }
];
